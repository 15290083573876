import { graphql, useStaticQuery } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      seguroSaudeHeaderMB: imageSharp(fluid: {originalName: { regex: "/mobile-seguro-saude-header/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seguroSaudeHeaderDesktopLG: imageSharp(fluid: {originalName: { regex: "/seguro-saude-desktop/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seguroSaudeUnimed: imageSharp(fluid: {originalName: { regex: "/seguro-saude-unimed/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seguroSaudeSulamerica: imageSharp(fluid: {originalName: { regex: "/seguro-saude-sulamerica/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seguroSaudeBradesco: imageSharp(fluid: {originalName: { regex: "/seguro-saude-bradesco/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seguroSaudeAmil: imageSharp(fluid: {originalName: { regex: "/seguro-saude-amil/" }}) {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      tudoPeloAppMB: imageSharp(fluid: {originalName: { regex: "/tudo-pelo-app-mobile/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seguroSaudeAns: imageSharp(fluid: {originalName: { regex: "/seguro-saude-ans-logo/" }}) {
        fluid(maxWidth: 130, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      qualicorpAns: imageSharp(fluid: {originalName: { regex: "/qualicorp-ans/" }}) {
        fluid(maxWidth: 125, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      sulamericaAns: imageSharp(fluid: {originalName: { regex: "/sulamerica-ans/" }}) {
        fluid(maxWidth: 125, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bradescoAns: imageSharp(fluid: {originalName: { regex: "/bradesco-ans/" }}) {
        fluid(maxWidth: 125, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      unimedAnsFooter: imageSharp(fluid: {originalName: { regex: "/unimed-ans/" }}) {
        fluid(maxWidth: 125, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      amilAns: imageSharp(fluid: {originalName: { regex: "/amil-ans/" }}) {
        fluid(maxWidth: 125, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerSemDoerNoBolso: imageSharp(fluid: {originalName: { regex: "/banner-seguro-saude-sem-doer-no-bolso/" }}) {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
